import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthGuard } from "./auth/guard";
import { AuthGuardGuest } from "./auth/guard/auth.guard-guest";
import { LayoutComponent } from "./layout/layout.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: 'login',
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
      canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
