import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class MessageService {
  constructor(
    private toastr: ToastrService,
    public router: Router,
  ) { }

  errmessage(err: any) {
    if (err != '')
      this.toastr.error(err);
  }

  succmessage(msg: any) {
    if (msg != '')
      this.toastr.success(msg);
  }

  messages(msg: any) {
    // this._snackBar.open(msg, "", {
    //   duration: 2000,
    //   panelClass: [css],
    // });
    console.log(msg);

  }

  dissuccmessage(msg: any) {
    const css = "green-snackbar";
    this.dismissMessages(msg, css);
  }

  disserrmessage(msg: any) {
    const css = "red-snackbar";
    this.dismissMessages(msg, css);
  }

  dismissMessages(msg: any, css: any) {
    // this._snackBar.open(msg, "X", {
    //   panelClass: [css],
    // });
  }
}
