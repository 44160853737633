import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";

import { MessageService } from "./message/message.service";
import { SharedService } from "./services/shared.service";
import { WebserviceService } from "./services/webservice.service";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from './services/common.service';
import { RouterModule } from '@angular/router';

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { PusherService } from './services/pusher.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LoaderComponent } from '../layout/loader/loader.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ChangePasswordComponent } from '../pages/change-password/change-password.component';
import { StakesComponent } from '../pages/stakes/stakes.component';
import { TopnavComponent } from '../layout/topnav/topnav.component';
import { LoginComponent } from '../auth/login/login.component';
import { BetListComponent } from '../pages/sports/bet-list/bet-list.component';
import { FilterPipe } from './pipes/filter.pipe';
import { MyBetListComponent } from '../pages/my-bet-list/my-bet-list.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { CasinoComponent } from '../pages/dashboard/casino/casino.component';
import { BetSlipComponent } from '../pages/sports/bet-slip/bet-slip.component';
import { PaginationComponent } from '../layout/pagination/pagination.component';



@NgModule({
  declarations: [
    SafeHtmlPipe,
    LoaderComponent,
    ChangePasswordComponent,
    StakesComponent,
    TopnavComponent,
    LoginComponent,
    BetListComponent,
    FilterPipe,
    MyBetListComponent,
    FooterComponent,
    CasinoComponent,
    BetSlipComponent,
    PaginationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: false,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  exports: [
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    ModalModule,
    TabsModule,
    CollapseModule,
    TooltipModule,
    TypeaheadModule,
    BsDropdownModule,
    AccordionModule,
    SafeHtmlPipe,
    LoaderComponent,
    ChangePasswordComponent,
    StakesComponent,
    TopnavComponent,
    LoginComponent,
    BetListComponent,
    FilterPipe,
    MyBetListComponent,
    FooterComponent,
    CasinoComponent,
    BetSlipComponent,
    PaginationComponent
  ],
  providers: [MessageService, SharedService, WebserviceService, CommonService, {
    provide: ToastrService
  }],
})
export class SharedModule { }
