import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/@shared/base-service/base-service';
import { HttpClient } from '@angular/common/http'
import { MessageService } from 'src/app/@shared/message/message.service';

@Injectable()
export class WebserviceService extends BaseService<any> {

  constructor(public http: HttpClient, public _message: MessageService) { 
    super(http, _message)
  }
}
